import React from "react";
import { useParams } from "react-router-dom";
import { useGetBookingConfirmationQuery } from "store/services/ticketServices";

function BookingConfirmation() {
  const { id } = useParams();
  const { data = null, isFetching } = useGetBookingConfirmationQuery(id);

  console.log("booking confirmation....", data);

  return (
    <div className="booking-confirmation">
      <div className="wrapper">
        <div className="page-hdr">
          <div className="title">Booking Confirmation</div>
        </div>
      </div>
    </div>
  );
}

export default BookingConfirmation;
