import React, { useState, useEffect } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { ChevronsIconRight } from "assets/icon";

export default function CheckoutForm({
  clientSecret,
  totalPrice,
  totalServiceFee,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (clientSecret) {
      stripe.retrievePaymentIntent(clientSecret).then((res) => {
        switch (res.paymentIntent.status) {
          case "succeeded":
            // setMessage("Payment succeeded!");
            break;
          case "processing":
            // setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            // setMessage("Your payment was not successful, please try again.");
            break;
          default:
            // setMessage("Something went wrong.");
            break;
        }
      });
    }
  }, [stripe, elements, clientSecret]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    let return_url = process.env.REACT_APP_STRIPE_RETURN_URL_DEV;

    switch (true) {
      case process.env.REACT_APP_ENVIRONMENT === "PRE_STAGING":
        return_url = process.env.REACT_APP_STRIPE_RETURN_URL_PRE_STAGING;
        break;
      case process.env.REACT_APP_ENVIRONMENT === "STAGING":
        return_url = process.env.REACT_APP_STRIPE_RETURN_URL_STAGING;
        break;
      case process.env.REACT_APP_ENVIRONMENT === "PROD":
        return_url = process.env.REACT_APP_STRIPE_RETURN_URL_PROD;
        break;
      default:
        break;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${return_url}/payment-check`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: {
      type: "accordion",
      defaultCollapsed: true,
      radios: true,
      spacedAccordionItems: true,
    },
  };

  return (
    <div>
      <div className="stripe-container">
        <form id="payment-form" onSubmit={handleSubmit}>
          <div className="stripe-form">
            <div className="form-container">
              <div style={{ marginBottom: "12px" }}>
                Select a payment method.
              </div>
              <PaymentElement
                id="payment-element"
                options={paymentElementOptions}
              />
            </div>
          </div>
          <div className="sub-total-blk">
            <div className="meta">
              <div className="title">
                {`Total (incl. tax & €${totalServiceFee} booking fee)`}
              </div>
              <div className="price">€{totalPrice.toFixed(2)}</div>
            </div>
            <div className="rit">
              <div className="pay-btn-container">
                <button
                  className="pay-btn"
                  disabled={isLoading || !stripe || !elements}
                  id="submit"
                >
                  <span id="button-text">
                    {isLoading ? (
                      <div className="spinner" id="spinner"></div>
                    ) : (
                      <div className="icon-container">
                        Pay <ChevronsIconRight />
                      </div>
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
