import React from "react";
import { Flex, rem, Text } from "@mantine/core";

import getRandomColor from "utils/getRandomColor";

const backgroundColor = getRandomColor();

const ProfilePicture = ({ schoolName, width = 90, height = 130 }) => {
  // Extract initials from the school name
  const initials = schoolName
    .split(" ")
    .map((word) => word[0])
    .join("")
    .toUpperCase();

  return (
    <Flex
      w={rem(width)}
      h={rem(height)}
      sx={{ borderRadius: rem(8) }}
      align="center"
      justify="center"
      bg={backgroundColor}
    >
      <Text color="#fff" size="xl" weight={600}>
        {initials}
      </Text>
    </Flex>
  );
};

export default React.memo(ProfilePicture);
