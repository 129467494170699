import Routing from "./routes/Routing";
import { ToastContainer } from "react-toastify";
import { MantineProvider } from "@mantine/core";
import { HelmetProvider } from "react-helmet-async";

// CSS
import "./assets/scss/index.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const windowLocation = window.location.href;

    if (windowLocation.includes("www")) {
      window.location.href = windowLocation.replace("www.", "");
    }
  }, []);

  return (
    <HelmetProvider>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{ fontFamily: "Inter" }}
      >
        <div className="App">
          <Routing />
          <ToastContainer
            autoClose={6000}
            position="top-right"
            className="toast-container"
          />
        </div>
      </MantineProvider>
    </HelmetProvider>
  );
}

export default App;
