let NEW_BOOKING_URL = "";

switch (process.env.REACT_APP_ENVIRONMENT) {
  case "DEV":
    NEW_BOOKING_URL = process.env.REACT_APP_STRIPE_RETURN_URL_DEV;
    break;
  case "PRE_STAGING":
    NEW_BOOKING_URL = process.env.REACT_APP_STRIPE_RETURN_URL_PRE_STAGING;
    break;
  case "STAGING":
    NEW_BOOKING_URL = process.env.REACT_APP_STRIPE_RETURN_URL_STAGING;
    break;
  case "PROD":
    NEW_BOOKING_URL = process.env.REACT_APP_STRIPE_RETURN_URL_PROD;
    break;
  default:
    NEW_BOOKING_URL = "localhost:3000";
    break;
}

export default NEW_BOOKING_URL;