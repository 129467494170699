import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";

import createPaymentIntent from "utils/createPaymentIntent";

import { ErrorAlert, Loader } from "components";

import { BOOK_SERVICE_BODY, BOOKING_ID, STRIPE_AC_ID } from "constants";

export default function IdealPayment() {
  const location = useLocation();
  const navigate = useNavigate();
  const { idealBank } = location.state;
  const reduxstate = useSelector((state) => state.State);
  const stripeAcId = window.localStorage.getItem(STRIPE_AC_ID);
  let bookServiceBody = window.localStorage.getItem(BOOK_SERVICE_BODY) || {};
  bookServiceBody = Object.keys(bookServiceBody).length
    ? JSON.parse(bookServiceBody)
    : {};

  const [stripe, setStripe] = useState(null);

  async function getClientSecret() {
    const body = {};

    if (reduxstate.currentDataFor === "service") {
      body.serviceId = bookServiceBody.service_id;
      body.slotDuration = bookServiceBody.duration;
      body.priceType = bookServiceBody.priceType;
    } else {
      body.scheduleId = reduxstate.id;

      const selectedEventPricing = reduxstate.selectedEventPricing
        .filter((item) => item.bookingQty && item.bookingQty > 0)
        .map(({ bookingQty, totalPrice, ticketType }) => ({
          bookingQty,
          totalPrice,
          ticketType,
        }));

      body.selectedEventPricing = selectedEventPricing;
    }

    console.log("TOTALPRICE", reduxstate.totalPrice);
    const res = await createPaymentIntent({
      amount: Number(reduxstate.totalPrice),
      body,
      paymentMethod: "ideal",
    });
    return res;
  }

  useEffect(() => {
    loadStripe(
      process.env.REACT_APP_ENVIRONMENT === "PROD"
        ? process.env.REACT_APP_STRIPE_LIVE_KEY
        : process.env.REACT_APP_STRIPE_TEST_KEY,
      stripeAcId ? { stripeAccount: stripeAcId } : undefined
    ).then(async (stripeRes) => {
      setStripe(stripeRes);

      const { client_secret, referenceId } = await getClientSecret();

      window.localStorage.setItem(BOOKING_ID, referenceId);

      let return_url = process.env.REACT_APP_STRIPE_RETURN_URL_DEV;

      switch (true) {
        case process.env.REACT_APP_ENVIRONMENT === "PRE_STAGING":
          return_url = process.env.REACT_APP_STRIPE_RETURN_URL_PRE_STAGING;
          break;
        case process.env.REACT_APP_ENVIRONMENT === "STAGING":
          return_url = process.env.REACT_APP_STRIPE_RETURN_URL_STAGING;
          break;
        case process.env.REACT_APP_ENVIRONMENT === "PROD":
          return_url = process.env.REACT_APP_STRIPE_RETURN_URL_PROD;
          break;
        default:
          break;
      }

      const { error, paymentIntent } = await stripeRes.confirmIdealPayment(
        client_secret,
        {
          payment_method: {
            ideal: {
              bank: idealBank,
            },
          },
          return_url: `${return_url}/payment-check`,
        }
      );

      if (paymentIntent) {
        if (paymentIntent.status === "succeeded") {
          if (reduxstate.currentDataFor === "ticket") {
            navigate("/payment-complete", {
              state: { paymentSuccess: true },
            });
          } else if (reduxstate.currentDataFor === "service") {
            navigate("/service-payment-complete", {
              state: { paymentSuccess: true },
              replace: true,
            });
          }
        }
      }

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.

      if (error.type === "card_error" || error.type === "validation_error") {
        confirmAlert({
          customUI: ({ onClose }) =>
            ErrorAlert({
              onClose: () => {
                onClose();
                navigate(-1);
              },
              error: error.message,
              serverError: false,
            }),
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      } else {
        confirmAlert({
          customUI: ({ onClose }) =>
            ErrorAlert({
              onClose: () => {
                onClose();
                navigate(-1);
              },
              error: "An unexpected error occurred.",
              serverError: false,
            }),
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      }
    });
  }, [stripeAcId]);

  if (!stripe) return null;

  return (
    <div className="ticket-view-loading">
      <Loader />
    </div>
  );
}
