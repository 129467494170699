import React from "react";

const ConfirmAlert = ({
  onClose,
  message,
  title,
  onConfirm,
  onDeny,
}) => {
  return (
    <div className="error-alert">
      <h1 className="title">{title}</h1>
      <p className="description">{message}</p>
      <div className="action-wrapper">
        <button
          className="action-deny"
          onClick={() => {
            onClose();
            onDeny();
          }}
        >
          No
        </button>
        <button
          className="action"
          onClick={() => {
            onClose();
            onConfirm();
          }}
        >
          Yes
        </button>
      </div>
    </div>
  );
};

export default ConfirmAlert;
