import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import CheckoutForm from "./CheckoutForm";
import { Loader } from "components";
import { useGetTicketsInfoQuery } from "store/services/ticketServices";
import { TimerIcon, LocationIcon, ChevronsIconLeft } from "assets/icon";

import { BASE_URL, BOOK_SERVICE_BODY } from "../../constants";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
const stripePromise = loadStripe(
  process.env.REACT_APP_ENVIRONMENT === "PROD"
    ? process.env.REACT_APP_STRIPE_LIVE_KEY
    : process.env.REACT_APP_STRIPE_TEST_KEY
);

export default function StripePayment() {
  const reduxstate = useSelector((state) => state.State);
  const id = window.localStorage.getItem("ticksID") || "";
  let bookServiceBody = window.localStorage.getItem(BOOK_SERVICE_BODY) || {};
  bookServiceBody = Object.keys(bookServiceBody).length
    ? JSON.parse(bookServiceBody)
    : {};
  const { data } = useGetTicketsInfoQuery(id);
  const navigate = useNavigate();

  const [clientSecret, setClientSecret] = useState();
  const [serviceDisplayData, setServiceDisplayData] = useState({});

  console.log("REDUXSTATE", reduxstate);

  useEffect(() => {
    (async () => {
      const body = {
        amount: reduxstate.totalPrice,
        currency: "eur",
        isPackage: false,
        //payment_method_types: ["card", "ideal"],
      };

      if (reduxstate.currentDataFor === "service") {
        body.serviceId = bookServiceBody.service_id;
        body.slotDuration = bookServiceBody.duration;
      } else {
        body.scheduleId = reduxstate.id;

        const selectedEventPricing = reduxstate.selectedEventPricing
          .filter((item) => item.bookingQty && item.bookingQty > 0)
          .map(({ bookingQty, totalPrice, ticketType }) => ({
            bookingQty,
            totalPrice,
            ticketType,
          }));

        body.selectedEventPricing = selectedEventPricing;
      }

      const { client_secret } = await fetch(
        BASE_URL + "/bookings/payments/createStripePaymentIntentUA",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      )
        .then((res) => res.json())
        .then((resJson) => {
          return resJson.statusCode === 200
            ? resJson.data
            : { client_secret: "" };
        });

      setClientSecret(client_secret);
    })();
  }, []);

  useEffect(() => {
    if (reduxstate.currentDataFor === "service") {
      const parsedDay = moment(bookServiceBody?.slot_date, "YYYY-MM-DD");

      setServiceDisplayData({
        classPhoto: reduxstate?.serviceData?.servicePhoto,
        className: reduxstate?.serviceData?.serviceName,
        schoolObj: {
          logo: reduxstate?.serviceData?.teacher_detail?.profilePic,
          schoolName: reduxstate?.serviceData?.teacher_detail?.fullName,
        },
        location: {
          addressName:
            reduxstate?.serviceData?.venue_detail?.address?.addressName,
          address: reduxstate?.serviceData?.venue_detail?.address?.address,
        },
        when: {
          day: parsedDay,
          start: bookServiceBody?.slot_start_time,
          end: bookServiceBody?.slot_end_time,
        },
      });
    }
  }, [reduxstate.currentDataFor]);

  return (
    <div className="stripe-payment">
      {clientSecret ? (
        <Elements
          options={{ appearance: { theme: "flat" }, clientSecret }}
          stripe={stripePromise}
        >
          <div>
            <div className="ticket-booking order-confirmation">
              <div className="wrapper">
                <div className="page-hdr">
                  <button className="back-btn" onClick={() => navigate(-1)}>
                    <ChevronsIconLeft />
                    <div className="lbl">Back</div>
                  </button>
                  <div className="title">
                    {"Pay now"}
                    &nbsp;
                    <span>4/4</span>
                  </div>
                </div>
                <div className="content">
                  {/* Ticket Info*/}
                  <div className="section">
                    <div className="block info">
                      <div className="sub-blk">
                        <div
                          className="image"
                          style={{
                            backgroundImage: `url(${
                              reduxstate?.currentDataFor === "service"
                                ? serviceDisplayData?.classPhoto
                                : data?.data?.classPhoto
                            })`,
                          }}
                        />
                        <div className="meta">
                          <div className="title">
                            {reduxstate?.currentDataFor === "service"
                              ? serviceDisplayData?.className
                              : data?.data?.className}
                          </div>
                          <div className="host">
                            <div
                              className="dp"
                              style={{
                                backgroundImage: `url(${
                                  reduxstate?.currentDataFor === "serivce"
                                    ? serviceDisplayData?.schoolObj?.logo
                                    : data?.data?.schoolObj?.logo
                                })`,
                              }}
                            />
                            <div className="host-meta">
                              <div className="by">Hosted by</div>
                              <div className="user-name">
                                {reduxstate?.currentDataFor === "service"
                                  ? serviceDisplayData?.schoolObj?.schoolName
                                  : data?.data?.schoolObj?.schoolName}
                              </div>
                            </div>
                          </div>
                          <div className="item">
                            <div className="icon">
                              <LocationIcon />
                            </div>
                            <div className="meta">
                              <div className="lbl">Where</div>
                              <div className="value">
                                {reduxstate?.currentDataFor === "service"
                                  ? serviceDisplayData?.location?.addressName
                                  : data?.data?.location?.addressName}
                              </div>
                              <div className="text">
                                {reduxstate?.currentDataFor === "service"
                                  ? serviceDisplayData?.location?.address
                                  : data?.data?.location?.address}
                              </div>
                            </div>
                          </div>
                          <div className="item">
                            <div className="icon">
                              <TimerIcon />
                            </div>
                            <div className="meta">
                              <div className="lbl">When</div>
                              <div className="value">
                                {reduxstate?.currentDataFor === "service" ? (
                                  <>
                                    {moment(
                                      serviceDisplayData?.when?.day
                                    ).format("dddd, MMMM D")}{" "}
                                    at {serviceDisplayData?.when?.start} -{" "}
                                    {serviceDisplayData?.when?.end}
                                  </>
                                ) : (
                                  `${moment(reduxstate?.classDate)?.format(
                                    "MMM Do YY, h:mm a"
                                  )} - ${moment(reduxstate?.classDate)
                                    ?.add(data?.data?.duration, "minutes")
                                    .format("MMM Do YY, h:mm a")}`
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CheckoutForm
            clientSecret={clientSecret}
            totalPrice={reduxstate.totalPrice}
            totalServiceFee={reduxstate.totalServiceFee}
          />
        </Elements>
      ) : (
        <div className="ticket-view-loading">
          <Loader size={40} />
        </div>
      )}
    </div>
  );
}
