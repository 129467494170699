import React from "react";

const ErrorAlert = ({ onClose, error, serverError }) => {
  return (
    <div className="error-alert">
      <h1 className="title">
        {serverError ? "Something went wrong!" : ""}
      </h1>
      <p className="description">{error}</p>
      <button className="action" onClick={onClose}>
        Ok
      </button>
    </div>
  );
};

export default ErrorAlert;
