import moment from "moment";
import { rem, Flex, Image, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import { LocationIcon, TimerIcon } from "assets/icon";
import { Loader } from "components";

export default function EventInfoCard({
  reduxstate,
  selectedStep,
  isReadMore,
  toggleReadMore,
}) {
  const isMobile = useMediaQuery("(max-width: 425px)");

  if (!reduxstate.eventData) {
    return (
      <Loader size={40} />
    )
  }

  const eventImage = reduxstate.eventData.classPhoto;
  const eventName = reduxstate.eventData.className;
  const schoolLogo = reduxstate.eventData.schoolObj.logo;
  const schoolName = reduxstate.eventData.schoolObj.schoolName;
  const where = reduxstate.location;
  const description = reduxstate.eventData.description;

  return (
    <div className="section">
      <div className="block info">
        <Flex direction="row" gap="md">
          <Image
            src={eventImage}
            height={rem(90)}
            width={rem(130)}
            radius="md"
          />
          <Flex direction="column" gap={rem(4)}>
            <Text size={isMobile ? "lg" : "xl"} weight={600} lh={rem(20)}>
              {eventName}
            </Text>
            <Flex direction="row" gap="sm" align="center">
              <Flex direction="column" gap={rem(5)}>
                <Flex direction="row" align="center" gap="xs">
                  <Image
                    src={schoolLogo}
                    height={rem(40)}
                    width={rem(40)}
                    radius="xl"
                  />
                  <Flex direction="column">
                    <Text color="dark" size={rem(13)} weight={600}>
                      Hosted by
                    </Text>
                    <Text color="dark.3" size={isMobile ? rem(10) : rem(14)}>
                      {schoolName}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        {selectedStep > 1 && (
          <Flex mt="xl" direction="column">
            <div className="item">
              <div className="icon">
                <LocationIcon />
              </div>
              <div className="meta">
                <div className="lbl">Where</div>
                <div className="value">{where.addressName}</div>
                <div className="text">{where.address}</div>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <TimerIcon />
              </div>
              <div className="meta">
                <div className="lbl">When</div>
                <div className="value">
                  {`${moment(reduxstate?.classDate).format(
                    "MMM Do, YYYY, h:mm a"
                  )} - ${moment(reduxstate?.classEndDate).format(
                    "h:mm a"
                  )}`}
                </div>
              </div>
            </div>
          </Flex>
        )}
        {selectedStep <= 1 && description && (
          <div className="about-blk">
            <div className="label">About Event</div>
            <div className="description">
              {isReadMore ? `${description.substring(0, 240)}...` : description}
              {""}
              <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? " Read more" : " Show less"}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
