import React from "react";

export default function GlobalFooter() {
  return (
    <footer className="footer-global">
      Powered by
      <a href="https://bookzyme.com" rel="noreferrer" target="_blank">
        BookzyMe
      </a>
    </footer>
  );
}
